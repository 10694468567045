* {
  margin: 0;
  padding: 0;
}

.set-home {
  position: absolute;
  bottom:0;
  
  width:100%;
}

.control-panel {
  position: absolute;
  top:0;
  right:0;
  font-size:0.8em
}
